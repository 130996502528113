import "@/polyfills";
import { createApp } from "vue";
import App from "@/App.vue";
import { createPinia } from "pinia";
import { router } from "@/router";
import { useUserStore } from "@/stores/user";
import { setupI18n } from "@/utils/i18n";
import { Unicon } from "@/icons";
import { updateRouteQuery } from "@/router/queryParams";
import { routingInjectionKey } from "@/keys";
import FloatingVue from "floating-vue";
import "@/assets/css/main.css";
import { setupTheme } from "@/theme";
import { useAuthStore } from "./stores/auth";
import PrimeVue from "primevue/config";
import { config as primeVueConfig } from "@/utils/primevue";
import { config as floatingVueConfig } from "@/utils/floatingVue";
import ToastService from "primevue/toastservice";

// Create the app instance
const app = createApp(App);

// Start up
startUp();

async function startUp() {
  const theme = import.meta.env.MODE === "production" ? setupTheme() : setupTheme("development");

  app.use(createPinia());
  app.use(Unicon, { height: 24, width: 24 });
  app.use(FloatingVue, floatingVueConfig);

  app.use(router);

  // Add global methods to the app
  app.provide(routingInjectionKey, {
    updateRouteQuery: query => updateRouteQuery(router, query)
  });

  // Setup user
  const user = useUserStore();
  const auth = useAuthStore();

  if (!auth.isLoggedIn) {
    await auth.refreshTokens();
  }
  if (auth.isLoggedIn) {
    await user.refreshData();
  }

  const locale = auth.isLoggedIn
    ? user.settings?.language || (await theme).defaultLanguage
    : (await theme).defaultLanguage;

  app.use(await setupI18n(locale));
  app.use(PrimeVue, primeVueConfig(locale));
  app.use(ToastService);

  // Mount the app
  app.mount("#app");
}
